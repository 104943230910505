/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

function Example() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className="bg-dark bg-gradient py-5" id="examples">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12">
                        <h2 className="pb-3">Examples:</h2>
                        <p>
                            Below is a podcast recording, followed by the first few paragraphs of the transcript
                            automatically generated by transcription service. Be sure to checkout out other podcasts
                            from <a href="https://www.beyondrockets.com/podcast">Beyond Rockets</a>
                        </p>
                        <div id="buzzsprout-player-11449856">

                            <iframe
                                src="https://www.buzzsprout.com/329585/11449856-episode-80-straight-to-ale-with-dan-perry-bob-gile-matthew-broadhurst?client_source=small_player&amp;iframe=true&amp;referrer=https://www.buzzsprout.com/329585/11449856-episode-80-straight-to-ale-with-dan-perry-bob-gile-matthew-broadhurst.js?container_id=buzzsprout-player-11449856&amp;player=small"
                                loading="lazy" width="100%" height="200" frameBorder="0" scrolling="no"
                                title="Beyond Rockets, Episode 80: Straight to Ale with Dan Perry, Bob Gile, &amp; Matthew Broadhurst"></iframe>

                        </div>
                        <br />
                        <Button hidden={open}
                            onClick={() => setOpen(!open)}
                            aria-controls="collapseExample"
                            aria-expanded={open}
                        >Show Example Transcript</Button>
                        <Button hidden={!open}
                            onClick={() => setOpen(!open)}
                            aria-controls="collapseExample"
                            aria-expanded={open}
                        >Hide Example Transcript</Button>
                        <Collapse in={open} className="py-5">
                            <div className="collapse" id="collapseExample">
                                <p>
                                    I'm your host, Clark Dunn. The Beyond Rockets podcast is a way for me to showcase and highlight some
                                    of
                                    the small business owners, entrepreneurs and talented creatives here in the rocket city that make
                                    Huntsville way more than just a rocket city. If you're not yet subscribed to the podcast, you can
                                    subscribe wherever you listen. You can follow me on YouTube at Beyond Rockets as well as Instagram
                                    at
                                    Beyond Rockets to stay up to date with new and exciting things happening in Huntsville as well as
                                    new
                                    episodes as they are released. Thank you so much for listening and I hope you enjoy.</p>
                                <p>This podcast is sponsored by Affinity Recruiting and Consulting. 94% of small businesses and
                                    nonprofits
                                    disappear before their 10th birthday. The top 6% survive and thrive for two reasons, talent and
                                    strategy. Affinity's team of experts provides talent acquisition, outsourced HR, strategic planning
                                    and
                                    fundraising support. Long story short, they fix the problems that cause small businesses and
                                    nonprofits
                                    to fail.</p>
                                <p>Welcome back to another episode of Beyond Rockets.</p>
                                <p>Today's event I'm talking with the founder, operations manager and head of production of Straight to
                                    Ale,
                                    a local brewery here in Huntsville in 2009. Thank you so much for joining me today. Would each of
                                    you
                                    like to introduce yourself and tell us a little bit about what it is your role here is at Straight
                                    to
                                    Ale? Dan should go first. Okay, I'll go first. Dan Perry, I'm the owner and I guess my official
                                    title is
                                    HMFIC. Bob?</p>
                                <p>Hi, I'm the brewery manager or brewery big dog, so I handle everything from grain to glass. My name
                                    is
                                    Matt Broder, I'm the operations manager, which just means I do whatever Dan doesn't want to do. So,
                                    Dan,
                                    tell me a little bit about the original backstory of Straight to Ale, because obviously you've been
                                    home
                                    brewing really since the late 80s, early 90s. Was there always an idea to kind of open up a brewery
                                    one
                                    day and kind of what were you doing in that and what originally got you interested in home brewing?
                                    Yeah, well, it's all about the beer. I really enjoyed home brewing. I liked the creativity. When I
                                    was
                                    in the service in Georgia, I sort of started home brewing and came back to Huntsville. And I joined
                                    what
                                    was at that time called the Madison Sebrati Club, which was the home brew club in town. And those
                                    guys
                                    were so helpful with my brewing because I was making serviceable beers, but nothing outstanding. And
                                    those guys, they were brutally honest and they're like, hey, your beer sucks. Hey, why it sucks? And
                                    they told me what I was doing wrong.</p>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Example;
