/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import { Container, Col, Row } from 'react-bootstrap';

function Details() {
    return (
        <Container fluid className="py-5 bg-dark bg-gradient">
            <Row className="justify-content-center">
                <Col lg={6} md={8} sm={10} xs={12}>
                    <h2 className="pb-3">Details:</h2>
                    <p>
                        This is an automatic transcription service using the latest available AI models. It provides
                        a summarization of the text, in addition to the full transcript which includes capitalization and
                        punctuation.
                    </p>
                    <p>
                        It typically takes 10 minutes to transcribe 30 minutes of audio/video. We will not finialize
                        your payment until the transcript has been completed and emailed successfully.
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default Details;
