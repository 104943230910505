/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import { Socket, io } from 'socket.io-client';
import React from 'react';

interface SocketContextType {
    socket: Socket;
}
export const socket = io();
export const SocketContext = React.createContext<SocketContextType>(
    {
        socket: socket,
    });