/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import axios from 'axios';
import { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import ReactGA from "react-ga4";

function FileInput() {

    const [loading, setLoading] = useState(false);

    // https://www.filestack.com/fileschool/react/react-file-upload/
    const setFileClick = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.currentTarget
        const inputElement = form[0] as HTMLInputElement;
        const files = inputElement.files;
        if (files && files?.length > 0) {

            ReactGA.event({
                action: "UploadFile",
                category: "UploadFile",
            });
    
            const data = new FormData();
            data.append("file", files[0]);
            data.append("filename", files[0].name);
            setLoading(true);
            const response = await axios.post("/upload", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            // TODO - set an error message to be displayed
            if(response.status !== 200) {
                console.log('Error uploading file');
            }
        }
    };
    
    return (
        <>
            <h3>2. Upload a file</h3>
            <Form id="upload-file-form" onSubmit={setFileClick} className="w-100 rounded-1 p-4 border bg-dark"
                encType="multipart/form-data">

                <Form.Label className="d-block mb-4">
                    <span className="form-label d-block">File to transcribe</span>
                    <Form.Control id="filename" required name="file" type="file" />
                </Form.Label>
                <div className="mb-3">
                    {loading ? 
                        <Spinner /> : 
                        <Button type="submit" className="px-3 rounded-3">
                            Upload
                        </Button>
                    }
                </div>
            </Form>
        </>
    );
}

export default FileInput;
