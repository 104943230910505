/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import EmailInput from './EmailInput';
import EmailDisplay from './EmailDisplay';
import FileInput from './FileInput';
import FileDisplay from './FileDisplay';
import Payment from './Payment';
import StartTranscription from './StartTranscription';

type InputSectionProps = {
    email: string; 
    filename: string; 
    format: string; 
    duration: string; 
    size: string;
    paymentRequired: boolean;
    paymentSuccess: boolean;
}
 
function InputSection( { email, filename, format, duration, size, paymentRequired, paymentSuccess}: InputSectionProps) {

    return (
        <Container fluid className="py-5 bg-dark bg-gradient">
            <Row id="input-section" className="justify-content-center">
                <Col lg={6} md={8} sm={10} xs={12}>
                    {!email && (
                        <EmailInput />
                    )}
                    {email &&(
                        <EmailDisplay email={email} />
                    )}
                    <br/>
                    {!filename && (
                        <FileInput />
                    )}
                    {filename && (
                        <FileDisplay filename={filename} format={format} duration={duration} size={size} />
                    )}
                    <br/>
                    <Payment email={email} filename={filename} paymentRequired={paymentRequired} paymentSuccess={paymentSuccess} />
                    {(paymentSuccess || !paymentRequired) && filename && email && (
                        <StartTranscription email={email} filename={filename} />
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default InputSection;
