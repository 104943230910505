/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import { SocketContext } from "../context/socket";
import React from 'react';
import { Button, Form } from "react-bootstrap";
import ReactGA from "react-ga4";

type FileDisplayProps = { 
    filename: string; 
    format: string; 
    duration: string; 
    size: string;
}

function FileDisplay({ filename, format, duration, size, }: FileDisplayProps ) {

    const { socket } = React.useContext(SocketContext);

    const clearFileClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        ReactGA.event({
            action: "ClearFile",
            category: "ClearFile",
        });
        socket.emit("clearFile");
    };

    return (
        <>
            <h3>2. File to transcribe:</h3>
            <Form onSubmit={clearFileClick} className="w-100 rounded-1 p-4 border bg-dark">
                <Form.Label className="d-block mb-4">
                    <span className="form-label d-block">File to transcribe</span>
                    <Form.Control readOnly type="text" placeholder={filename} />
                </Form.Label>
                <div className="col-auto">
                    <Form.Label className="d-block mb-4">
                        <span className="form-label d-block">File format</span>
                        <Form.Control readOnly type="text" placeholder={format} />
                    </Form.Label>
                </div>
                <div className="col-auto">
                    <Form.Label className="d-block mb-4">
                        <span className="form-label d-block">File duration</span>
                        <Form.Control readOnly type="text" placeholder={duration} />
                    </Form.Label>
                </div>
                <div className="col-auto">
                    <Form.Label className="d-block mb-4">
                        <span className="form-label d-block">File size</span>
                        <Form.Control readOnly type="text" placeholder={size} />
                    </Form.Label>
                </div>
                <div className="mb-3">
                    <Button type="submit" className="px-3 rounded-3">
                        Clear
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default FileDisplay;
