/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import { SocketContext } from "../context/socket";
import React from 'react';
import { useState } from 'react';
import { Button, Form, Spinner } from "react-bootstrap";
import ReactGA from "react-ga4";

type StartTranscriptionProps = {
    email: string;
    filename: string;
}

function StartTranscription({ email, filename }: StartTranscriptionProps) {

    const { socket } = React.useContext(SocketContext);

    const [loading, setLoading] = useState(false);

    const startTranscriptionClick = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        ReactGA.event({
            action: "StartTranscription",
            category: "StartTranscription",
        });
        setLoading(true);
        socket.emit("startTranscription");
    };

    return (
        <>
            <h3 className="pt-5">All Set!</h3>
            <Form id="start-transcription" onClick={startTranscriptionClick} className="w-100 rounded-1 p-4 border bg-dark" >
                <p>We will transcribe the file {filename} and send it to {email}. Click the button below to start the process:</p>
                {loading ? 
                    <Spinner /> : 
                    <Button type="submit">Start Transcription Process</Button>
                }
            </Form>
        </>
    );
}

export default StartTranscription;
