/**
 
Flask App supporting audio/video transcription.

This app runs a flask app with a UI for transcribing audio/video files
to text. It uses Stripe to handle payment processing and AWS DynamoDB
to track status.

Author: J. Langley
Email: jlangley@hsv.ai
Copyright: Copyright 2022, Huntsville AI, LLC
License: No license for this code is granted unless provided in writing

*/

import { SocketContext } from "../context/socket";
import React from 'react';
import axios from 'axios';
import { ReactComponent as DownloadIcon } from './download.svg';

import { Container, Col, Row, Form, Button } from 'react-bootstrap';

import { useEffect, useState, useRef } from 'react';

import ReactGA from "react-ga4";

interface ProgressData {
    status: string,
    message: string,
    transcript: string,
    download: string
};

type ProgressProps = {
    fileKey: string;
}

function InProgress({ fileKey }: ProgressProps) {

    const { socket } = React.useContext(SocketContext);

    const [start] = useState<Date>(new Date());
    const [currentString, setCurrentString] = useState("");
    const [progress, setProgress] = useState<ProgressData>();

    const stateRef = useRef<Date>(start);
    const timeIntervalRef = useRef<NodeJS.Timer>();
    const progressIntervalRef = useRef<NodeJS.Timer>();

    const clearClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log("CLearing Session");
        ReactGA.event({
            action: "ClearSession",
            category: "ClearSession",
        }); 
       socket.emit("clear");
    };

    const getNewTime = () => {
        let temp = 0;
        temp = (new Date()).valueOf() - stateRef.current.valueOf();
        const date = new Date(0, 0, 0, 0, 0, 0, temp);
        const output = date.getHours().toString().padStart(2, "0") +
            "h:" + date.getMinutes().toString().padStart(2, "0") +
            "m:" + date.getSeconds().toString().padStart(2, "0") + "s";
        setCurrentString(currentString => output);
    };

    useEffect(() => {

        timeIntervalRef.current = setInterval(() => {
            getNewTime();
        }, 1000);

        return () => {
            if (timeIntervalRef.current !== undefined) {
                const timerRef  = timeIntervalRef.current as NodeJS.Timeout;
                clearInterval(timerRef);
            }
        }
        
    },[currentString]);

    
    useEffect(() => {

        progressIntervalRef.current = setInterval(() => {
            const params = {
                file_key: fileKey
            }
            axios.get("/get-progress", { params })
                .then(res => {
                    const data = res.data as ProgressData;
                    if (data.transcript !== "UNKNOWN") {
                        ReactGA.event({
                            action: "CompleteTranscription",
                            category: "CompleteTranscription",
                        });
    
                        if (timeIntervalRef.current !== undefined) {
                            const timerRef  = timeIntervalRef.current as NodeJS.Timeout;
                            clearInterval(timerRef);
                        }
    
                        if(progressIntervalRef.current !== undefined) {
                            const timerRef = progressIntervalRef.current as NodeJS.Timeout;
                            clearInterval(timerRef);
                        }
                    }
                    setProgress(progress => data);
                })
            }, 10000);

        return () => {
            if(progressIntervalRef.current !== undefined) {
                const timerRef = progressIntervalRef.current as NodeJS.Timeout;
                clearInterval(timerRef);
            }
        }
        
    },[progress, fileKey]);

    return (
        <>
            <Container fluid className="bg-dark bg-gradient py-5">
                <Row className="justify-content-center">
                    <Col lg={6} md={8} sm={10} xs={12} >
                        <h2 className="pb-3">Progress:</h2>
                        <p>Time: {currentString}</p>
                        {progress?.message && (
                            <>
                                <p>Status: {progress.message}</p>
                            </>
                        )}
                        {!progress?.message && (
                            <>
                                <p>Status will appear here after automatic refresh.</p>
                            </>
                        )}
                        {progress?.transcript && (progress.transcript !== "UNKNOWN") && (
                            <>
                                <Form>
                                <a href="/download-transcript" download="transcript">
                                    <Button type="button">
                                        <DownloadIcon />Download</Button></a>
                                <Button onClick={clearClick} className="ms-2">Start Over</Button>
                                </Form>
                                <div className="pt-5" dangerouslySetInnerHTML={{ __html: progress.transcript }} />
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default InProgress;
